import * as React from "react";
import { Container } from "react-bootstrap";
import { graphql } from "gatsby";
import Feature from "../../components/Feature";
import Layout from "../../components/layout";
import CallToAction from "../../components/CallToAction";
import FeaturesSummary from "../../components/productPage/featuresSummary";
import {
  youtubeContainer,
  youtubeEmbed,
} from "./for-charter-operators.module.css";
import { SEO } from "../../components/seo";

const ForCharterOperatorsPage = ({ data }) => {
  return (
    <Layout pageTitle="Floatist for Charter Operators">
      {/* <Container fluid className={container}> */}

      <Feature
        title="Transform Your Bareboat Charter Operations with Floatist"
        subtitle="At Floatist, we understand the unique challenges that come with managing a bareboat charter company. Your days are filled with tight schedules, various teams to coordinate, and a constant stream of guests eager to embark on their next adventure. It's a complex operation that demands precision and efficiency—two things that traditional methods like pen and paper, spreadsheets, and outdated software can no longer provide. "
        imageData={data.file.childImageSharp.gatsbyImageData}
        textSide="right"
      />

      <Container fluid className={youtubeContainer}>
        <div className={youtubeEmbed}>
          <iframe
            style={{ borderRadius: "12px" }}
            width="840"
            height="473"
            src="https://www.youtube.com/embed/oMx8XkLlnjI"
            title="Youtube Video Player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </Container>

      <FeaturesSummary />

      {/* </Container> */}

      {/* TODO */}
      <CallToAction />
    </Layout>
  );
};

export default ForCharterOperatorsPage;

export const Head = () => (
  <SEO
    description="Discover Floatist, the ultimate yacht management solution
    designed to streamline operations and enhance communication among yacht
    owners, captains, and service providers. Experience unparalleled efficiency
    and transparency in every aspect of yacht management with Floatist's
    innovative platform."
  />
);

export const query = graphql`
  query {
    file(relativePath: { eq: "charter-base.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
