import * as React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import PrimaryButton from "../styles/styled-component/PrimaryButton";
import { section, title, button } from "./CallToAction.module.css";

const CallToAction = ({ backgroundColor }) => {
  return (
    <Container fluid className={section} style={{ backgroundColor }}>
      <h1 className={title}>Ready to get started?</h1>
      <PrimaryButton size="lg" className={button}>
        <Link to="/contact">Request a Demo</Link>
      </PrimaryButton>
    </Container>
  );
};

export default CallToAction;
